// @font-face {
//     font-family: 'NAME_OF_YOUR_WEBFONT';
//     src: url('PATH/TO/YOUR_WEBFONT_HERE.eot');
//     src: url('PATH/TO/YOUR_WEBFONT_HERE.eot?#iefix') format('embedded-opentype'),
//          url('PATH/TO/YOUR_WEBFONT_HERE.woff') format('woff'),
//          url('PATH/TO/YOUR_WEBFONT_HERE.ttf') format('truetype'),
//          url('PATH/TO/YOUR_WEBFONT_HERE.svg#NAME_OF_YOUR_WEBFONT') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

@font-face {
  font-family: 'abel_regular';
  src: url('../fonts/abel/Abel-Regular.eot?#iefix') format('embedded-opentype'),  
  	   url('../fonts/abel/Abel-Regular.woff') format('woff'), 
  	   url('../fonts/abel/Abel-Regular.ttf')  format('truetype'), 
  	   url('../fonts/abel/Abel-Regular.svg#Abel-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
    font-family: 'abril_fatfaceregular';
    src: url('../fonts/abrilfatface/abrilfatface-regular-webfont.eot');
    src: url('../fonts/abrilfatface/abrilfatface-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/abrilfatface/abrilfatface-regular-webfont.woff') format('woff'),
         url('../fonts/abrilfatface/abrilfatface-regular-webfont.ttf') format('truetype'),
         url('../fonts/abrilfatface/abrilfatface-regular-webfont.svg#abril_fatfaceregular') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'aileronbold';
    src: url('../fonts/aileron/aileron-bold-webfont.eot');
    src: url('../fonts/aileron/aileron-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/aileron/aileron-bold-webfont.woff') format('woff'),
         url('../fonts/aileron/aileron-bold-webfont.ttf') format('truetype'),
         url('../fonts/aileron/aileron-bold-webfont.svg#aileronbold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'aileronlight';
    src: url('../fonts/aileron/aileron-light-webfont.eot');
    src: url('../fonts/aileron/aileron-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/aileron/aileron-light-webfont.woff') format('woff'),
         url('../fonts/aileron/aileron-light-webfont.ttf') format('truetype'),
         url('../fonts/aileron/aileron-light-webfont.svg#aileronlight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'aileronregular';
    src: url('../fonts/aileron/aileron-regular-webfont.eot');
    src: url('../fonts/aileron/aileron-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/aileron/aileron-regular-webfont.woff') format('woff'),
         url('../fonts/aileron/aileron-regular-webfont.ttf') format('truetype'),
         url('../fonts/aileron/aileron-regular-webfont.svg#aileronregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'aileronthin';
    src: url('../fonts/aileron/aileron-thin-webfont.eot');
    src: url('../fonts/aileron/aileron-thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/aileron/aileron-thin-webfont.woff') format('woff'),
         url('../fonts/aileron/aileron-thin-webfont.ttf') format('truetype'),
         url('../fonts/aileron/aileron-thin-webfont.svg#aileronthin') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'aileronultralight';
    src: url('../fonts/aileron/aileron-ultralight-webfont.eot');
    src: url('../fonts/aileron/aileron-ultralight-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/aileron/aileron-ultralight-webfont.woff') format('woff'),
         url('../fonts/aileron/aileron-ultralight-webfont.ttf') format('truetype'),
         url('../fonts/aileron/aileron-ultralight-webfont.svg#aileronultralight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
	font-family: 'karlabold';
	src: url('../fonts/karla/karla-bold-webfont.eot');
	src: url('../fonts/karla/karla-bold-webfont.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/karla/karla-bold-webfont.woff') format('woff'),
		 url('../fonts/karla/karla-bold-webfont.ttf') format('truetype'),
		 url('../fonts/karla/karla-bold-webfont.svg#karlabold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'karlaregular';
	src: url('../fonts/karla/karla-regular-webfont.eot');
	src: url('../fonts/karla/karla-regular-webfont.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/karla/karla-regular-webfont.woff') format('woff'),
		 url('../fonts/karla/karla-regular-webfont.ttf') format('truetype'),
		 url('../fonts/karla/karla-regular-webfont.svg#karlaregular') format('svg');
	font-weight: normal;
	font-style: normal;
}



@font-face {
    font-family: 'unica_oneregular';
    src: url('../fonts/unica/unicaone-regular-webfont.eot');
    src: url('../fonts/unica/unicaone-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/unica/unicaone-regular-webfont.woff') format('woff'),
         url('../fonts/unica/unicaone-regular-webfont.ttf') format('truetype'),
         url('../fonts/unica/unicaone-regular-webfont.svg#unica_oneregular') format('svg');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'playfair_display_scBlkIt';
    src: url('../fonts/playfairdisplaysc/playfairdisplaysc-blackitalic-webfont.eot');
    src: url('../fonts/playfairdisplaysc/playfairdisplaysc-blackitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/playfairdisplaysc/playfairdisplaysc-blackitalic-webfont.woff') format('woff'),
         url('../fonts/playfairdisplaysc/playfairdisplaysc-blackitalic-webfont.ttf') format('truetype'),
         url('../fonts/playfairdisplaysc/playfairdisplaysc-blackitalic-webfont.svg#playfair_display_scBlkIt') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'u47';
    src: url('../fonts/u47/2760F4_0_0.eot');
    src: url('../fonts/u47/2760F4_0_0.eot?#iefix') format('embedded-opentype'),
         url('../fonts/u47/2760F4_0_0.woff') format('woff'),
         url('../fonts/u47/2760F4_0_0.ttf') format('truetype'),
         url('../fonts/u47/2760F4_0_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'u67';
    src: url('../fonts/u67/276103_1_0.eot');
    src: url('../fonts/u67/276103_1_0.eot?#iefix') format('embedded-opentype'),
         url('../fonts/u67/276103_1_0.woff') format('woff'),
         url('../fonts/u67/276103_1_0.ttf') format('truetype'),
         url('../fonts/u67/276103_1_0.svg#wf') format('svg');
}
