html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html,
body{
	
	color: @color-copy;
	font-family: karlaregular;
	font-weight: @light;
	font-size: @base-font-size;
	line-height: @base-line-height;	

	height: ~'calc((@{miniHeight} * 13) + 10px)';

	@media screen and (min-width: @BPsmall){
		height: (@mediumHeight * 8) + 10px;
	}

	@media screen and (min-width: @BPmedium){
		height: (@mediumHeight * 6) + 10px;
	}

	@media screen and (min-width: @BPlarge){
		height: 100%;
	}
}

body{
	padding: 5px
}

.master{
	position: relative;
	width: 100%;
	height:100%;
}

.header{
	position: fixed;
	top: 0;
	left: 0;
	height: @height_header;
	width: 100%;
	background-color: @color-brand;
	color: @color-copy-contrast;
}

.main{
	padding-top: @height_header;
	min-height: 100%;
	margin-bottom: -@height_footer;  

	&:after {
		content: "";
		display: block;
		height: @height_footer;		
	}
}

.footer{
	position: relative;
	float: left;
	width: 100%;
	height: @height_footer;
	background-color: @color-copy;
	color: @color-copy-contrast;
}